
import { defineComponent } from 'vue';
import SignInForm from '@/components/forms/account/sign-in/SignInForm.vue';
import GoogleRecaptcha from '@/components/GoogleRecaptcha.vue';
import MainLogo from '@/layout/header/partials/MainLogo.vue';

export default defineComponent({
  name: 'sign-in-page',
  components: {
    MainLogo,
    SignInForm,
    GoogleRecaptcha,
  },
});
